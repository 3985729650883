const $ = require("jquery")

export const onInitialClientRender = () => {
  // // jQuery sample call
  // $(document).ready(function () {
  //   console.log("jQuery is working !")
  // })

  // SignIn form handling
  $(function () {
    $("#sign_in").submit(function () {
      $("#sign_in > input[type=text]").addClass("error")
      $("#sign_in > input[type=password]").val("")
    })
  })
  $("input").on("click focusin", function () {
    $("#sign_in > input").removeClass("error")
    this.value = ""
  })

  //Text animation
  $(function () {
    $(".signin .page-tlt, .error-page .page-tlt").textillate({
      loop: true,
      in: {
        effect: "fadeInRightBig",
      },
      out: {
        effect: "flipInY",
        delayScale: 1.5,
        delay: 50,
        sync: false,
        shuffle: false,
        reverse: false,
        callback: function () {},
      },
    })
  })
}
