// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artist-archives-artist-slideshow-tsx": () => import("./../../../src/pages/artist/_archives/artistSlideshow.tsx" /* webpackChunkName: "component---src-pages-artist-archives-artist-slideshow-tsx" */),
  "component---src-pages-artist-archives-artist-tsx": () => import("./../../../src/pages/artist/_archives/artist.tsx" /* webpackChunkName: "component---src-pages-artist-archives-artist-tsx" */),
  "component---src-pages-artist-stathis-karapanos-tsx": () => import("./../../../src/pages/artist/Stathis-Karapanos.tsx" /* webpackChunkName: "component---src-pages-artist-stathis-karapanos-tsx" */),
  "component---src-pages-artist-stathis-karapanos-wip-2-tsx": () => import("./../../../src/pages/artist/Stathis-Karapanos-wip2.tsx" /* webpackChunkName: "component---src-pages-artist-stathis-karapanos-wip-2-tsx" */),
  "component---src-pages-artist-stathis-karapanos-wip-3-tsx": () => import("./../../../src/pages/artist/Stathis-Karapanos-wip3.tsx" /* webpackChunkName: "component---src-pages-artist-stathis-karapanos-wip-3-tsx" */),
  "component---src-pages-artist-stathis-karapanos-wip-tsx": () => import("./../../../src/pages/artist/Stathis-Karapanos-wip.tsx" /* webpackChunkName: "component---src-pages-artist-stathis-karapanos-wip-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

